import {React, useState, useRef} from 'react'
import './index.css'
import Heading from '../../Components/Heading'
import Footer from '../../Components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faMobile, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import emailjs from 'email-js'

function Contact(props) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_0gv74ut', 'service_0gv74ut', form.current, 'Q4okDMR0CzlHmWF4b')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (
    <div ref={props.contact} className='contact-container' id='Contact'>
      <Heading className='heading' color='white' smallHeading='32px' text='Get in Touch'/>
      <div className="contact-main">
        {/* <div className="contact-left">

         <div className="left-container">

          <div className="address">
            <div className="address-section">
              <FontAwesomeIcon className='address-icon' icon={faLocationDot}/>
              <p className="address-text">
                Block-B, Door No.3, Hansa
              <br />
               Building, RK Swamy Centre, 147, 
              <br />
              Pathari Rd, Thousand Lights,
              <br />
              Chennai, Tamil Nadu-600006
              </p>
            </div>
          </div>

          <div className="mobile">
            <div className="address-section"> 
              <FontAwesomeIcon className='mobile-icon' icon={faMobile}/>
              <p className="mobile-text"> 
                +91 99625 18992
              </p>
            </div>
          </div>

          <div className="email">
            <div className="address-section">
              <FontAwesomeIcon className='email-icon' icon={faEnvelope}/>
              <p className="email-text">
                info@wltm.in
              </p>
            </div>
          </div>

        </div>
      </div> */}

          <form ref={form} onSubmit={sendEmail}>
            <div className="form-wrapper">
              <div className="form-group">
                <input 
                  type="text" 
                  className="name" 
                  placeholder='Your Name'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                   />
              </div>
              <div className="form-group">
                <input 
                  type="email" 
                  className="email1" 
                  placeholder='Your Email' 
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  />
              </div>
              <div className="form-group">
                <input 
                  type="text" 
                  className="subject" 
                  placeholder='Subject' 
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  />
              </div>
              <div className="form-group">
                <textarea cols="30" rows="10" 
                  className="message"  
                  placeholder='Message'
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  >
                  </textarea>
              </div>
              <div className="submit-container">
                <input type="submit" className="submit" />
              </div>
            </div>
          </form>    
  
      </div>

      <Footer />
    </div>
  )
}

export default Contact