import React from 'react'
import './index.css'

function Heading({text,btn,btnclick,color, smallHeading}) {


  return (
    <div className='heading-container'>
        <h2 style={{color: color}}>{text}<div className='dot'></div></h2>
        {btn && <button onclick={btnclick} className='btn'>Back</button>}
    </div>
  )
}

export default Heading