import {React, useState} from 'react'
import './index.css'
import {NavLink, useNavigate} from 'react-router-dom';
import logo from '../../Assets/logo-wlt.png'
import logo1 from '../../Assets/logo-black.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'


function Navbar(props) {
    const [clicked, setClicked] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)
    const [scrollDown, setScrollDown ] = useState(false)

    const clickHandler = () => {
      setClicked(!clicked)
      setMobileMenu(!mobileMenu)
    }

    const hidenavHandler = () => {
      setMobileMenu(!mobileMenu)
      setClicked(!clicked)
    }

    // const scrollToSection = (ref) => {
    //   window.scrollTo({
    //     top: ref.current.offsetTop,
    //     behavior: 'smooth'
    //   })
    // }

    const scrollHandler = () => {
      if(window.scrollY > 100){
        setScrollDown(true)
      } else {
        setScrollDown(false)
      }
    }

    window.addEventListener('scroll',scrollHandler)

  return (
    // <div className={scrollDown?'navbar scrolled':'navbar'}>
    //   <div className="logo-container">
    //     <img onClick={() => props.scrollToSection(props.home)} className='logo' src={logo} alt="WLT" />
    //     {/* {whiteLogo
    //     ?<img className='logo' src={logo} alt="WLT" />
    //     :<img className='logo' src={logo1} alt="WLT" />
    //     } */}
    //   </div>
    //   <div onClick={hidenavHandler} className={mobileMenu?'mobile-list': 'navbar-list-wrapper'}>
    //     <ul className='navbar-list'>
    //       <li onClick={() => props.scrollToSection(props.home)} className="navbar-links navlink">HOME
    //         {/* <NavLink to='/' activeclassname='active' className='navlink'>HOME</NavLink> */}
    //         {/* <a href="#" className='navlink'>HOME</a> */}
    //       </li>
    //       <li onClick={() => props.scrollToSection(props.services)} className="navbar-links navlink">SERVICES
    //         {/* <NavLink onClick={logoHandler} to='Services' activeclassname='active' className='navlink'>SERVICES</NavLink> */}
    //         {/* <a href="#Services" className='navlink'>SERVICES</a> */}
    //       </li>
    //       <li onClick={() => props.scrollToSection(props.celebrities)} className="navbar-links navlink">CELEBRITIES
    //         {/* <NavLink onClick={logoHandler} to='Celebrities' activeclassname='active' className='navlink'>CELEBRITIES</NavLink> */}
    //         {/* <a href="#Celebrities" className='navlink'>CELEBRITIES</a> */}
    //       </li>
    //       <li onClick={() => props.scrollToSection(props.customers)} className="navbar-links navlink">CUSTOMERS
    //         {/* <NavLink onClick={logoHandler} to='Customers' activeclassname='active' className='navlink'>CUSTOMERS</NavLink> */}
    //         {/* <a href="#Customers" className='navlink'>CUSTOMERS</a> */}
    //       </li>
    //       <li onClick={() => props.scrollToSection(props.contact)} className="navbar-links navlink">CONTACT
    //         {/* <NavLink onClick={logoHandler} to='Contact'  activeclassname='active' className='navlink'>CONTACT</NavLink> */}
    //         {/* <a href="#Contact" className='navlink'>CONTACT</a> */}
    //       </li>
    //     </ul>
    //   </div>
    //   <FontAwesomeIcon onClick={clickHandler} className='mobile-menu' icon={clicked? faTimes : faBars} size='2x'/>    
    // </div>

    // <div className={scrollDown?'navbar scrolled':'navbar'}>
    //   <div  className='nav-left'>
    //     <div onClick={hidenavHandler} className={mobileMenu?'mobile-list': 'navbar-list-wrapper'}>
    //       <img onClick={() => props.scrollToSection(props.home)} className='logo' src={logo} alt="WLT" />
    //       <ul className='navbar-list'>
    //         <li onClick={() => props.scrollToSection(props.home)} className="navbar-links navlink">HOME</li>
    //         <li onClick={() => props.scrollToSection(props.services)} className="navbar-links navlink">SERVICES</li>
    //         <li onClick={() => props.scrollToSection(props.celebrities)} className="navbar-links navlink">CELEBRITIES</li>
    //         <li onClick={() => props.scrollToSection(props.customers)} className="navbar-links navlink">CUSTOMERS</li>
    //       </ul>
    //     </div>
    //   </div>

    //   <ul className={mobileMenu?'contact-item':'nav-right'}>
    //     <li onClick={() => props.scrollToSection(props.contact)} className={scrollDown?"navbar-links navlink white-text":"navbar-links navlink"}>CONTACT</li>    
    //   </ul>
    //   <FontAwesomeIcon onClick={clickHandler} className='mobile-menu' icon={clicked? faTimes : faBars} size='2x'/>
    // </div>

    <div className={"navbar-container"}>

      <div className={scrollDown?'navbar-desktop scrolled': 'navbar-desktop'}>
        <div className="navbar-left">
          <img onClick={() => props.scrollToSection(props.home)} className='logo' src={logo} alt="WLT" />
        </div>
        <div className="navbar-right">
        <ul className="navbar-list">
            <li onClick={() => props.scrollToSection(props.home)} className={scrollDown?"navbar-links navlink white-text":"navbar-links navlink"}>HOME</li>
            <li onClick={() => props.scrollToSection(props.services)} className={scrollDown?"navbar-links navlink white-text":"navbar-links navlink"}>SERVICES</li>
            <li onClick={() => props.scrollToSection(props.celebrities)} className={scrollDown?"navbar-links navlink white-text":"navbar-links navlink"}>CELEBRITIES</li>
            <li onClick={() => props.scrollToSection(props.customers)} className={scrollDown?"navbar-links navlink white-text":"navbar-links navlink"}>CUSTOMERS</li>
            <li onClick={() => props.scrollToSection(props.contact)} className={scrollDown?"navbar-links navlink white-text":"navbar-links navlink"}>CONTACT</li> 
        </ul>   
        </div>
      </div>

      <div className={scrollDown?"navbar-mobile scrolled": "navbar-mobile"}>
        <div className="logo">
          <img onClick={() => props.scrollToSection(props.home)} className='logo' src={logo} alt="WLT" />
        </div>
        <div className="mobile-logo">
          <FontAwesomeIcon onClick={clickHandler} className='mobile-menu' icon={clicked? faTimes : faBars} size='2x'/>
        </div>
        {
        clicked &&
        <ul className="mobile-list">
            <li onClick={() => props.scrollToSection(props.home)} className="navbar-links navlink">HOME</li>
            <li onClick={() => props.scrollToSection(props.services)} className="navbar-links navlink">SERVICES</li>
            <li onClick={() => props.scrollToSection(props.celebrities)} className="navbar-links navlink">CELEBRITIES</li>
            <li onClick={() => props.scrollToSection(props.customers)} className="navbar-links navlink">CUSTOMERS</li>
            <div onClick={() => props.scrollToSection(props.contact)} className="navbar-links navlink">CONTACT</div> 
        </ul>
        } 
      </div>
  
    </div>
  )
}

export default Navbar