import React from 'react'
import './index.css'

function DescriptionHeading({text, color}) {
  return (
    <div className='desc-heading'>
        <h4 style={{color: color}}>{text}<div className='dot'></div></h4>
    </div>
  )
}

export default DescriptionHeading