import React from 'react'
import CelebHeading from '../../../Components/CelebHeading';
import DescriptionHeading from '../../../Components/DescriptionHeading';
import './index.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Washi from '../../../Assets/CelebPage/Washington.png'
import Hari from '../../../Assets/CelebPage/HariH.jpeg'
import Lydian from '../../../Assets/CelebPage/lydian2.jpeg'
import Neeraj from '../../../Assets/CelebPage/NeerajH.jpg'
import Siddharth from '../../../Assets/CelebPage/SiddharthH.jpg'
import backBtn from '../../../Assets/backBtn.png'

function Celeb(props) {

  const { state } = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const backToCeleb = () => {
    navigate('/')
    // props.scrollToSection(props.celebrities)
  }
  return (
    <div className='celeb-container'>
      {/* <button onClick={backToCeleb} className='btn'>Back</button> */}
          <div className='image-container'>
            <div className="bg-img">
              <img src={
                  state.name==='Washington Sundar'
                  ? Washi
                  : state.name==='Hari Nishanth'
                  ? Hari
                  : state.name==='Lydian Nadhaswaram'
                  ? Lydian
                  : state.name==='Siddharth Manimaran'
                  ? Siddharth
                  : state.name==='Neeraj Madhav'
                  ? Neeraj
                  : null
                } alt='' />
            </div>
            <div className="overlay"> </div>
            <div onClick={backToCeleb} className="back-btn"><img src={backBtn} alt="" /></div>
            <div className="celeb-header">
              <CelebHeading heading={state.name} text={state.category}/>
            </div>
          </div> 
  
          <div className='description-container'>
            
            <div className="description">
              <DescriptionHeading text='BIO'/>
              <p>
                {state.text1}
              </p>
              <p> 
                {state.text2}
              </p>
            </div>
          
          </div>
    </div>
  )
}

export default Celeb
