import {React, useState} from 'react'
import './index.css'
import Heading from '../../Components/Heading'
import Washi from '../../Assets/CelebritiesHome/Washington.jpg'
import Hari from '../../Assets/CelebritiesHome/HariH.jpeg'
import Lydian from '../../Assets/CelebritiesHome/LydianH.jpg'
import Neeraj from '../../Assets/CelebritiesHome/NeerajH.jpg'
import Siddharth from '../../Assets/CelebritiesHome/SiddharthH.jpg'
import Celeb from './Celeb'
import { BrowserRouter as Router, Routes, Route, Outlet, Link } from "react-router-dom"

function Celebrities(props) {

  const [sports, setSports] = useState(true)
  const [entertainment, setEntertainment] = useState(false)

  const sportsCategory = () => {
    setSports(true)
    setEntertainment(false)
  }

  const entertainmentCategory = () => {
    setEntertainment(true)
    setSports(false)
  }

  const data = [
    {name: 'Washington Sundar', category: 'CRICKETER', image:'Washi',
    text1:
    'Washington Sundar is an Indian cricketer who has represented his country at the international level. He bats left-handed and bowls right-arm off-spin. Hewas an all-rounder for the Indian national under-19 cricket squad. On December 13, 2017,he made his international debut for India against Sri Lanka.', 
    text2:
    'He made his first-class debut for Tamil Nadu in the 2016–17 Ranji Trophy on 6 October 2016.Like Ravichandran Ashwin from Tamil Nadu before him, Washington Sundar went from being a batsman as a youngster to making his name as an offspinner. In October 2017, he scored his maiden first-class century, batting for Tamil Nadu against Tripura in the 2017–18 Ranji Trophy.He was also selected for the India U-19 world cup in 2016.',
    },
    {name: 'Hari Nishanth', category: 'CRICKETER', image:'Hari', 
    text1:
    'C Hari Nishant, also known as Chezhian Harinishanth, was born in Ooty, Tamil Nadu, on August 16, 1996. He began playing cricket at a young age. His family lived in Udumalpet at first, then moved to Coimbatore to enable Nishanth obtain more professional cricket experience.', 
    text2:
    "Nishanth's sister put up her dream of becoming a doctor in order to help him get cricket equipment. He is a left-handed hitter with a right-arm break bowling technique. He has formerly represented Tamil Nadu, Dindigul Dragons, and VB Kanchi Veterans.",
    },
    {name: 'Lydian Nadhaswaram', category: 'MUSICIAN', image:'Lydian',
    text1:
    "WLydian Nadhaswaram is a young Tamil Nadu-based Indian musician. In 2019, he competed on CBS's The World's Best and won, winning a $1 million prize.", 
    text2:
    "In September 2019, it was announced that Lydian would make his debut as a film composer with the Malayalam-language fantasy film Barroz: Guardian of D'Gama's Treasure, directed by Mohanlal, starring himself in the lead.He played the role of Guddu in the film Atkan Chatkan.",
    },
    {name: 'Siddharth Manimaran', category: 'CRICKETER', image:'Siddharth',
    text1:
    "Manimaran Siddharth is a cricketer from India. On November 22,2019, he made his Twenty20 debut for Tamil Nadu in the 2019–20 Syed Mushtaq Ali Trophy. He made his first-class debut for Tamil Nadu in the 2019–20 Ranji Trophy on December 9,2019.", 
    text2:
    "In the 2020 IPL auction, he was bought by the Kolkata Knight Riders ahead of the 2020 Indian Premier League.In February 2021, Siddharth was bought by the Delhi Capitals in the IPL auction ahead of the 2021 Indian Premier League. He made his List A debut on 20 February 2021, for Tamil Nadu in the 2020–21 Vijay Hazare Trophy.",
    },
    {name: 'Neeraj Madhav', category: 'ACTOR', image:'Neeraj', 
    text1:
    "Neeraj Madhav is an Indian actor, rapper, and dancer who primarily works in the Malayalam film industry. Neeraj is most known for his role as Moosa Rehman in the Indian online series The Family Man, which premiered in October 2019 on Amazon Prime Video.", 
    text2:
    "Neeraj made his entry to the Malayalam cinema industry through Raaj Prabavathy Menon's Buddy into which he was selected through an audition. He was later noticed by director Jeethu Joseph and was invited to be a part of his movies Memories and Drishyam, both turned out to be box office successes, with the latter becoming the highest-grossing Malayalam film ever. Meanwhile, Neeraj also acted in Abrid Shine's 1983 and Sathyan Anthikad's Oru Indian Pranayakadha.",
    }
  ]
  
  return (
        <div ref={props.celebrities} className='celebrity-container' >    
          <Heading text='TALENT'/>
          <div className='celeb-category'>
            <p onClick={sportsCategory} className={sports? 'active' : 'inactive' }>Sports</p>
            <hr/>
            <p onClick={entertainmentCategory} className={entertainment? 'active' : 'inactive' }>Entertainment</p>
          </div>
          {sports ?
          <div className='grid-container1'>
              <Link to='Celeb' className='row-1' state={data[0]}><img src={Washi} alt="" /></Link>
              <Link to='Celeb' className='row-1' state={data[3]}><img src={Siddharth} alt="" /></Link>
              <Link to='Celeb' className='row-1' state={data[1]}><img src={Hari} alt="" /></Link>
          </div>
          :
          <div className='grid-container2'>
              <Link to='Celeb' className='row-1' state={data[2]}><img src={Lydian} alt="" /></Link>
              <Link to='Celeb' className='row-1' state={data[4]}><img src={Neeraj} alt="" /></Link>
          </div>

          }
        </div>
  )
}

export default Celebrities