import React from 'react'
import './index.css'

function CelebHeading(props) {
  return (
    <div className='celeb-header'>
      <h1>{props.heading}</h1>
      <p>{props.text}</p>
    </div>
  )
}

export default CelebHeading