import React, {useRef} from 'react'
import './index.css'
import talent from '../../Assets/Services/talent.jpg'
import licensing from '../../Assets/Services/licensing.jpg'
import influencer from '../../Assets/Services/influencer.jpg'
import digital from '../../Assets/Services/digital.jpg'
import production from '../../Assets/Services/production.jpg'
import pr from '../../Assets/Services/pr.jpg'
import creative from '../../Assets/Services/creative.jpg'
import Heading from '../../Components/Heading'

function Services(props) {


  return (
    <div ref={props.services} className='services-container' id="Services">
      <Heading text='WHAT WE DO'/>
      <div className="services-main">
        <div className="services">
          <img src={talent} alt="WLT" />
          <h2>Talent Manager</h2>
        </div>
        <div className="services">
          <img src={licensing} alt="WLT" />
          <h2>Licensing and Merchandising</h2>
        </div>
        <div className="services">
          <img src={influencer} alt="WLT" />
          <h2>Influencer Marketing</h2>
        </div>
        <div className="services">
          <img src={digital} alt="WLT" />
          <h2>Digital Solution</h2>
        </div>
        <div className="services">
          <img src={production} alt="WLT" />
          <h2>Production house</h2>
        </div>
        <div className="services">
          <img src={pr} alt="WLT" />
          <h2>PR</h2>
        </div>
        <div className="services last-item">
          <img src={creative} alt="WLT" />
          <h2>Creative Collaboration</h2>
        </div>
  
      </div>
      
      {/* <div className="header-container">
        <h2>Talent Manager<span>.</span></h2>
      </div>
      <div className="header-container">
        <h2>Licensing and Merchandising<span>.</span></h2>
      </div>
      <div className="header-container">
        <h2>Influencer Marketing<span>.</span></h2>
      </div>
      <div className="header-container">
        <h2>Digital Solution<span>.</span></h2>
      </div>
      <div className="header-container">
        <h2>Production House<span>.</span></h2>
      </div>
      <div className="header-container">
        <h2>PR<span>.</span></h2>
      </div>
      <div className="header-container">
        <h2>Creative Collaboration<span></span></h2>
      </div> */}
    </div>
  )
}

export default Services