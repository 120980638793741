import './App.css';
import {useRef} from 'react'
import Home from './Pages/Home'
import Navbar from './Components/Navbar'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Services from './Pages/Services'
import Contact from './Pages/Contact'
import Customers from './Pages/Customers'
import Celebrities from './Pages/Celebrities'
import Celeb from './Pages/Celebrities/Celeb';


function App() {
  const home = useRef(null) 
  const services = useRef(null)
  const celebrities = useRef(null)
  const customers = useRef(null)
  const contact = useRef(null)
  const navbar = useRef(null)
  
  const scrollToSection = (ref) => {
    // console.log(ref)
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    })
  }

  return (
      <div className="App">
        <Router>         
          <Routes>
            
            <Route path='/' 
              element={
                <>
                <Navbar scrollToSection={scrollToSection} home={home} services={services} customers={customers} celebrities={celebrities} contact={contact}/>
                <Home scrollToSection={scrollToSection} services={services} home={home}/>
                <Services services={services}/>
                <Celebrities celebrities={celebrities}/>
                <Customers customers={customers}/>
                <Contact contact={contact}/>
                </>
              }>
            </Route>
            <Route path='Celeb' element={<Celeb celebrities={celebrities} scrollToSection={scrollToSection}/>}></Route>  
          </Routes>     
        </Router>
        {/* <Home />
        <Services/>
        <Celebrities />
        <Customers />
        <Contact/> */}
      </div> 
  );
}

export default App;

{/*     <Router>
          <Navbar/>
          <Routes>      
            <Route path='/' element={<Home/>} />
            <Route path='Services' element={<Services/>} />
            <Route path='Celebrities' element={<Celebrities/>}></Route>
            <Route path='Celebrities/Celeb' element={<Celeb/>}></Route>
            <Route path='Customers' element={<Customers/>} />
            <Route path='Contact' element={<Contact/>} />
          </Routes>
        </Router> */}