import styled from 'styled-components'

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
    background-color: #fff;
    margin: 0 15px;
`