import React from 'react'
import './index.css'
import twitter from '../../Assets/twitter.png'
import facebook from '../../Assets/facebook.png'
import insta from '../../Assets/insta.png'
import logo from '../../Assets/logo-wlt.png'

const Footer = () => {
  return (
    <div className='footer-container'>
        <div className="footer-left">
            <img className='logo-footer' src={logo} alt="WLT" />
            <div className="address-container-mobile">
              <p className="address-text">
                  Block-B, Door No.3, Hansa 
                  <br /> 
                  Building, RK Swamy Centre,
                  <br /> 
                  147, Pathari Rd, Thousand 
                  <br />
                  Lights, Chennai, Tamil Nadu 
                  <br /> 
                  600006
              </p>
            </div>
            
            <div className="address-container">
              <p className="address-text">
                  Block-B, Door No.3, Hansa Building, RK Swamy Centre, 147, 
                <br />
                Pathari Rd, Thousand Lights, Chennai, Tamil Nadu-600006
              </p>
            </div>

            <div className="rights">
             <p className='address-text'>© 2022 All Rights Reserved </p>
            </div>
        </div>

        <div className="footer-right">
            <div className="info">
            <p className="email-text">info@wltm.in</p>
              <p className="mobile-text">+91 99625 18992</p>
            </div>
            <div className="social-media">
              <a><img src={twitter} alt="" /></a>
              <a><img src={facebook} alt="" /></a>
              <a><img src={insta} alt="" /></a>
            </div>
        </div>
        <div className="rights-mobile">
             <p className='address-text'>© 2022 All Rights Reserved </p>
            </div>

    </div>
  )
}

export default Footer
