import {React, useRef} from 'react'
import './index.css'
import Heading from '../../Components/Heading'
import Item from './Item'
import Carousel from 'react-elastic-carousel'
import Anker from '../../Assets/Logos/anker.png'
import Colexion from '../../Assets/Logos/colexion.png'
import Dream11 from '../../Assets/Logos/dream11.png'
import Gillete from '../../Assets/Logos/gillette.png'
import Listerine from '../../Assets/Logos/listerine.png'
import Prime from '../../Assets/Logos/prime.png'
import Puma from '../../Assets/Logos/puma.png'
import Sg from '../../Assets/Logos/sg.png'
import Sixit from '../../Assets/Logos/sixit.png'
import Ss from '../../Assets/Logos/ss.png'


function Customers(props) {
  const carouselRef = useRef()
  const items= [
    {image: Anker},
    {image: Colexion},
    {image: Dream11},
    {image: Gillete},
    {image: Listerine},
    {image: Prime},
    {image: Puma},
    {image: Sg},
    {image: Sixit},
    {image: Ss}
  ]
  
  const breakPoints = [
    {width: 1, itemsToShow:1},
    {width: 550, itemsToShow:2},
    {width: 768, itemsToShow:3},
    {width: 1200, itemsToShow:4}
  ];

  return (
    <div ref={props.customers} className='customers-container' id='Customers'>
      <Heading text='CUSTOMERS'/>
      {/* */}
      <Carousel ref={carouselRef} enableAutoPlay autoPlaySpeed={2000} breakPoints={breakPoints}
      // onNextEnd={
      //   (item, index) => {
      //     let id = item.item.children.props.id
      //     if(index==2 && id!=2) {
      //       carouselRef.current.goTo(0)
      //     }
      //   }
      // } 
      >
        {items.map((item, index) => <Item key={index}><img className='img-logo' id={index} src={item.image} alt="" /></Item>)}
      </Carousel>
    </div>
  )
}

export default Customers