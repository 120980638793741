import React from 'react'
import './index.css'
import Navbar from '../../Components/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import {useNavigate} from 'react-router-dom'

// style='color: #82ece8;'

function Home(props) {

    // const navigate = useNavigate()
    console.log(props.scrollToSection)
  return (
    <div ref={props.home} className='container'>
            <div className="section-left">
                <div className="heading">
                    <h1>
                        WE  
                        {' '}<FontAwesomeIcon className='heart' icon={faHeart} />
                    <br/>
                        TALENT
                    </h1>
                </div>
            </div>
        <div className="section-right">
            <div className='content'>
                <h1>
                    INDIA-BASED <span>TALENT
                    <br />
                    MANAGEMENT AGENCY</span> IN
                    <br />
                    THE <span>SPORTS</span> AND
                    <br />
                    <span>ENTERTAINMENT</span>
                    <br />
                    INDUSTRIES
                </h1>
                <p className="story">
                    Founded in 2018, We work with clients to provide a wide range of solutions, including celebrity endorsements, sponsorships, IP development and affiliations, and brand activations to name a few.
                </p>
                <div onClick={() => props.scrollToSection(props.services)} className='link'>OUR SERVICES</div>
            </div>
        </div>
    </div>
  )
}

export default Home